import React from 'react';
import "./photos.css";

import ImagesPathLink from "./images.json";

function Phototest() {

  return (
    <div className="phototest_page">   

        <div className="photos_container">


          {
          ImagesPathLink.map( imagePath => {
            return(
              <div className="box-image" key={imagePath.id}>
                <img src={imagePath.img} alt=""></img>
                {/* <div className="box-label">
                  <div>
                     <h2 className="photo_label">{imagePath.label}</h2>
                  </div>
                </div> */}
              </div>
            )

          })
        }
             <div className="box-image fill-width border-none fill-width">
                <i></i>
              </div>

            <div className="box-image border-none fill-width">
                <i></i>
              </div>
              <div className="box-image border-none  fill-width">
                <i></i>
              </div>     
              <div className="box-image border-none fill-width">
                <i></i>
              </div>

              <div className="box-image border-none fill-width">
                <i></i> 
              </div>
       

          
        </div>




    </div>
  )
}

export default Phototest