import React from 'react'

function Subscribing() {
    return (
        <>
            {/* color: white;
  background: wheat;
  width: 83%;
  margin-left: 7.5%;
  text-align: left; */}

            <hr class="h-px bg-[wheat] w-/5 lg:w-4/5 text-left my-8 bg-white-200 border-0 dark:bg-white-700
            lg:ml-[2%]" />

            <div className="w-[100%] md:w-[100%] md:ml-[0%] lg:ml-[0%] lg:w-[96%] 
            xl:w-[84%] xl:max-w-[130rem]  xl:mx-auto            
            mdxl:ml-[2%] 2xl:w-[95%]   5xl:ml-[2%]  
            mx-auto relative isolate overflow-hidden bg py-16 sm:py-12 lg:py-32">
                <div className="ml-[2%] sm:ml-[4%] md:ml-[1%]   px-4 lg:px-2">
                    <div className="ml-0 grid max-w-2xl grid-cols-1 gap-x-2 gap-y-16 lg:max-w-none lg:grid-cols-2">
                        <div className="max-w-xl flex flex-col lg:max-w-lg">
                            <h2 className="text-3xl flex font-bold tracking-tight text-white sm:text-4xl">
                                Inscrivez-vous à la newsletter
                            </h2>
                            <p className="mt-4 flex text-lg leading-8 text-gray-300">
                                Pour vous tenir au courant des nouvelles opportunités
                            </p>
                            <div className="mt-6 flex max-w-md gap-x-2">
                                <label for="email-address" className="sr-only">
                                    Addresse e-mail
                                </label>
                                <input id="email-address" name="email" type="email" autocomplete="email" required
                                    className="text-[black] min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white 
                                shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-yellow-500
                                sm:text-sm sm:leading-6" placeholder="angelina-jolie@belle-et-jolie.com" />
                                <button type="submit" className="text-[black] tracking-wide flex-none rounded-md bg-[wheat] text-black px-3.5 py-2.5 text-sm 
                                font-semibold shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 
                                focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                                    Inscription
                                </button>
                            </div>
                        </div>
                        <dl className="flex flex-row flex-wrap gap-x-8 lg:gap-x-16 items-end  gap-y-10 sm:grid-cols-2 lg:pt-2">
                            <div className="flex flex-col items-start">
                                <div className="rounded-md bg-white/5 mb-4 p-2 ring-1 ring-[wheat]">
                                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>
                                </div>
                                <dt className="mt-4 font-semibold text-white text-left">Articles hebdomadaire</dt>
                                <dd className="mt-2 leading-7 text-gray-400 text-left">Des articles pour vous inspirer</dd>
                            </div>
                            <div className="flex flex-col items-start">
                                <div className="rounded-md bg-white/5 p-2 mb-4 ring-1 ring-[wheat]">
                                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                         d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002" />
                                    </svg>
                                </div>
                                <dt className="mt-4 font-semibold text-white text-left">
                                    Information de valeur
                                </dt>
                                <dd className="mt-2 leading-7 text-gray-400 text-left">
                                    Nous considérerons que votre temps est précieux
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                {/* <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
                <div className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
            </div> */}
            </div>
        </>

    )
}

export default Subscribing