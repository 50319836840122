import React from 'react';
import { motion } from 'framer-motion';
import "./services.css";
const servicesData = [
    {
        icon: "/icons/advices.png",
        title: "Conseils",
        description: "Fort de 10 années d'expérience, je donne des conseils avisés et personnalisés. Mon expertise garantit des résultats fiables et de qualité. N'hésitez pas à me contacter pour une collaboration fructueuse."
    },
    {
        icon: "/icons/quality.png",
        title: "Qualité",
        description: "Mon expérience et mes connaissances approfondies, reconnues et appréciées, assurent à tous mes clients un service de la plus haute qualité. Votre satisfaction est ma priorité."
    },
    {
        icon: "/icons/network.png",
        title: "Réseau",
        description: "Mon réseau étendu d'artisans et de créateurs talentueux me permet de proposer des solutions idéalement adaptées à chaque projet. Mon accompagnement est guidé par l'expertise et l'engagement."
    },
    {
        icon: "/icons/innovation.png",
        title: "Innovation",
        description: "Je suis constamment à la recherche de nouveautés, collaborant avec des créateurs innovants et utilisant des technologies de pointe, pour vous offrir des solutions avant-gardistes."
    },
    {
        icon: "/icons/follow.png",
        title: "Suivi",
        description: "Grâce à des outils de suivi modernes, je gère efficacement chaque projet, assurant une réponse rapide et adaptée à vos besoins, quelle que soit votre situation, et c'est ce que vous voulez."
    },
    {
        icon: "/icons/excellence.png",
        title: "Excellence",
        description: "L'excellence est la pierre angulaire de ma démarche professionnelle, validée par une réputation solide et des retours clients positifs. Mon objectif est de toujours fournir le meilleur service possible."
    }
];


function Services() {

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };


    return (
        <div>
            <section className="my-8 section core-section border-top-g mx-auto 5xl:max-w-[1900px]">
                <div className="container mx-auto my-8">
                    <div className="grid grid-cols-12 my-16 gap-y-6 md:gap-y-12 gap-x-6 md:gap-x-12">
                        {servicesData.map((service, index) => (
                            <motion.div
                                key={index}
                                className="w-[97%] md:w-[100%]  mx-auto col-span-12 sm:col-span-6 lg:col-span-4"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                variants={variants}
                            >
                                <div key={index} className="w-[97%] md:w-[100%] mx-auto col-span-12 sm:col-span-6 lg:col-span-4">
                                    <div className="feature-box-02">
                                        <div className="icon mb-2">
                                            <img className="mt-0 img-icon text-theme opacity-100 rounded-[50%]" 
                                            src={service.icon} alt={service.title} />
                                        </div>
                                        <div className="feature-content col">
                                            <h4 className='text-2xl mb-3 tracking-wide'>{service.title}</h4>
                                            <p className='text-xl md:text-[1.35rem] leading-18 tracking-wider'>{service.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Services