import React, { useState } from 'react';
import "./faq.css";

function FAQ() {

    const [openFaqId, setOpenFaqId] = useState(null);

    const toggleFaq = (id) => {
        if (openFaqId === id) {
            setOpenFaqId(null);
        } else {
            setOpenFaqId(id);
        }
    };


    const faqData = [
        {
            id: 1,
            title: "En quoi consiste le coaching pour améliorer mon style vestimentaire ?",
            description: "Je vais vous conseiller et vous diriger vers des process qui vous sont adapter pour améliorer votre style personnel et exprimer votre individualité à travers la mode. Profitez de conseils personnalisés pour choisir des vêtements qui vous valorisent et reflètent votre essence unique"
        },
        {
            id: 2,
            title: "Que puis-je attendre de ma première consultation de style ?",
            description: "Lors de votre première consultation, nous évaluerons votre style actuel et discuterons de vos objectifs de mode. Recevez des recommandations personnalisées pour transformer votre garde-robe et votre image"
        },
        {
            id: 3,
            title: "Ai-je besoin d'une préparation spéciale avant ma consultation en stylisme ?",
            description: "Aucune préparation spéciale n'est nécessaire. Venez telle que vous êtes, prête à explorer de nouveaux styles et des idées pour réinventer votre image."
        },
        {
            id: 4,
            title: "Est-ce que c'est seulement pour le titre personnel, ou est-ce aussi possible pour le style professionnel ?",
            description: "C'est possible pour les deux, c'est vous qui définissez l'objectif et je vous accompagnerai"
        },
        {
            id: 5,
            title: "Est-ce que la personnalité que je montre risque d'être modifié",
            description: "Le but est de continuer à exprimer votre personnalité ou celle que vous voulez exprimer. Si elle est modifié ça sera avec votre validation pour mieux exprimer votre essence"
        }
    ];

    const FaqItem = faqData.map((el) => (
        <React.Fragment key={el.id}>
            <div className='2xl:max-w-[100rem]'>
                <button
                    className="flex items-center focus:outline-none"
                    onClick={() => toggleFaq(el.id)} >
                    <svg className="flex-shrink-0 w-6 h-6 text-gold-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" 
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path>
                    </svg>
                    <h1 className="mx-4 text-lg sm:text-[1.4rem] md:text-xl xl:text-[1.7rem] 
                    text-left text-white dark:text-white tracking-wide">{el.title}</h1>
                </button>

                <div className={`flex mt-4 md:mx-10 faq-element ${openFaqId === el.id ? 'reveal' : ''}`}>
                    <p className={`${openFaqId === el.id ? 'faq-element.reveal' : 'faq-element'} 
                        leading-7 tracking-wide text-md sm:text-xl md:text-xl xl:text-[1.4rem] text-left 
                        max-w-[100%] px-4 text-white dark:text-white `}>
                        {el.description}
                    </p>
                </div>

            </div>

            <hr className="my-8 border-gray-200 dark:border-gray-700" />
        </React.Fragment>
    ));

    return (
        <div className='page faq-page'>
            <section className="bg-dark dark:bg-gray-900 w-[100%] md:w-[70%] md:max-w-[138rem]  pb-12 mx-auto">
                <div className="container px-6 pt-12 mx-auto">
                    <h1 className="pt-0 xl:pt-12 text-3xl video_container_title  pb-8 font-semibold text-center text-white lg:text-3xl xl:text-3xl dark:text-white">
                        Réponses aux questions les plus fréquentes
                    </h1>
                    <div className="mt-[10vh] xl:mt-[14vh] lg:flex lg:-mx-auto 2xl:mt-30">
                        {/* <div className="lg:mx-12">
                            <h1 className="text-xl font-semibold text-gray-800 dark:text-white">Table of Content</h1>

                            <div className="mt-4 space-y-4 lg:mt-8">
                                <a href="#" className="block text-blue-500 dark:text-blue-400 hover:underline">General</a>
                                <a href="#" className="block text-gray-500 dark:text-gray-300 hover:underline">Trust & Safety</a>
                                <a href="#" className="block text-gray-500 dark:text-gray-300 hover:underline">Services</a>
                                <a href="#" className="block text-gray-500 dark:text-gray-300 hover:underline">Billing</a>
                                <a href="#" className="block text-gray-500 dark:text-gray-300 hover:underline">Office Cleaning</a>
                            </div>
                        </div> */}

                        <div className="flex-1 mt-8 lg:mx-12 lg:mt-0">
                            {FaqItem}
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default FAQ