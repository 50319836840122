import React from 'react';
import { useEffect, useRef, useState } from 'react';
import 'react-slideshow-image/dist/styles.css';
import "./../../components/ImageShow/imageShow.css";
import FeaturesBlocks from '../../components/featuresBlocks';
import Services from '../Photos/Services';
import { motion } from 'framer-motion';
import "./presentation.css";
import HomeTest from '../../components/HomeTest/HomeTest';
import Subscribing from '../../components/Subscribing/Subscribing';

function Presentation() {
  
  const [myElementIsVisiblen, setElementIsVisible] = useState();

  console.log(myElementIsVisiblen);
  const myRef = useRef();


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setElementIsVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current);
  }, [])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 5, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };


  const variantsFeatures = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
};



  return (



      
    <div ref={myRef} className="page home_page">

      {/* <motion.h1
        className="stylist_name"
        variants={itemVariants}
        initial="hidden"
        animate="visible"
      >
        Fashioniza
      </motion.h1> */}


      <motion.div
        className="aboutMeSection"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className='about_global_container'>
          <div className="about_me_container">
            <motion.div variants={itemVariants}>
              <h3>Coach style vestimentaire</h3>
              <p>Basé <strong className='city'>à Paris</strong></p>
              <p>J'aide les femmes à mieux s'habiller avec classe. Mes connaissances et mon expérience pour vous rendre resplendissante.</p>
              <ul className='list_tag_container'>
                <li className="list_tag">
                  <span className="list_tag_icon"></span>
                  <p>Analyste vestimentaire</p>
                </li>
                <li className="list_tag">
                  <span className="list_tag_icon"></span>
                  <p>Amélioration de l'image</p>
                </li>
                <li className="list_tag">
                  <span className="list_tag_icon"></span>
                  <p>Coaching langage corporel</p>
                </li>
              </ul>
            </motion.div>
          </div>

          <div className="about_me_container">
            <motion.img
              src="/images/stylist.jpg"
              alt="Stylist"
              variants={itemVariants}
            />
          </div>
          
        </div>
      </motion.div>

      <div className="home_first_frame">

        <HomeTest />

        {/* <div className='container-slide'>
              <Fade>
              {fadeImages.map((fadeImage, index) => (
                <div className="each-fade" key={index}>
                  <div className="image-fade-container container_image_show">
                      <img src={fadeImage.url} className="image_show_card" />
                      <img src={fadeImage.url2} className="image_show_card" />
                  </div>                  
                </div>))}
              </Fade>
          </div>
         */}


        {/* <div className="video_content">
          <Link to="/videos" href="videos.html" className="button_to_video_text"><span className="hidden-mobile-screen"></span>Témoignages et entrevues</Link>
        </div> */}

        {/* <hr className="page_separation" /> */}

      </div>

      <Services />
      <hr className="page_separation" />
      <FeaturesBlocks />

      <Subscribing />
    </div>


  )
}

export default Presentation







/* */


{/* <div className="introduce_video"> 

            <iframe className="youtube_video" width="560" height="315" src="https://www.youtube.com/embed/DraqgP2Pbwo" title="YouTube video player" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    
               
                      
      </div> */}