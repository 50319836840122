import React from 'react'
import "./Footer.css";

function Footer() {
  return (
    <div>

<footer>
    <ul>
      <li>Confidentialité</li>
      <li>Mention légales</li>
    </ul>
  </footer>

    </div>
  )
}

export default Footer