import React, { Component, useState } from "react";
import Slider from "react-slick";

import { ReactComponent as PlayLogo } from '../../assets/svg/play-solid.svg';
import { ReactComponent as PauseLogo } from '../../assets/svg/pause-solid.svg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./homeTest.css";
import PhotoCard from "./PhotoCard";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", scale: "1.4", position: "absolute", right: "-1.7rem", zIndex: "5", textStroke: "medium" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", scale: "1.4", position: "absolute", left: "-1.7rem", zIndex: "5", textStroke: "medium" }}
      onClick={onClick}
    />
  );
}

class HomeTest extends Component {
  constructor() {
    super();
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.state = {
      showButtonControlls: true,
      hideButtonControlls: false,


      slides: [
        {
          img: "/images/book_page/1.jpg"
        },
        {
          img: "/images/book_page/2.jpg"
        },
        {
          img: "/images/book_page/3.jpg"
        },
        {
          img: "/images/book_page/4.jpg"
        },
        {
          img: "/images/book_page/5.jpg"
        },
        {
          img: "/images/book_page/6.jpg"
        },
        {
          img: "/images/book_page/7.jpg"
        },
        {
          img: "/images/book_page/8.jpg"
        },
        {
          img: "/images/book_page/9.jpg"
        }

      ]
    };
  }
  play() {
    this.slider.slickPlay();
    this.setState((curState) => {
      return { showButtonControlls: !curState.showButtonControlls }
    });
  }
  pause() {
    this.slider.slickPause();
    this.setState((curState) => {
      return { showButtonControlls: !curState.showButtonControlls }
    });
  }
  render() {
    var settings = {
      dots: true,
      infinite: false,
      lazyLoad: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
      autoplay: true,
      autoplaySpeed: 2000,
      initialSlide: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 2200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        }


      ],
      // toggleControlButton() {
      //   this.setState((curState) => {
      //     return { showButtonControlls: !curState.showButtonControlls }
      //   });
      // },

      //      ControlButtonF() {

      //     const [controlButton, setControlButton] = useState(true);
      //     const handleClickControlButtonVisibility = () => {
      //       setControlButton(!controlButton);
      //     }


      // }
    };




    return (
      <div className="home_test_page w-full  mx-auto">
        {/* <h2> Portfolio</h2> */}
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {this.state.slides.map((slide, index) => {
            return (
              <div key={index}>
                <PhotoCard imgSrc={slide.img} />
                {/* <img src={slide.img} alt={`slide${index}`} /> */}
              </div>
            );
          })}

        </Slider>
        <div className="butons_control_container ">
          <button className="button" onClick={this.play} style={{ display: this.state.showButtonControlls ? "none" : "flex" }}><PlayLogo /></button>
          <button className="button" onClick={this.pause} style={{ display: this.state.showButtonControlls ? "flex" : "none" }}><PauseLogo /></button>
        </div>
        {/* <div></div> */}
      </div>
    );
  }
}

export default HomeTest;
