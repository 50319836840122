const pictureData = [
    { imgSrc: "/images/works/img2.jpg", category: "accessories", description: "Sunshine eyes, eyeglasses", title: "The sun is shining. The look too" },
    { imgSrc: "/images/works/img7.jpg", category: "fashion", description: "Red dress for princess", title: "Raspberries" },
    { imgSrc: "/images/works/img1.jpg", category: "casual", description: "Style and wind", title: "The wind carries me away, so does the style" },
    { imgSrc: "/images/works/img4.jpg", category: "accessoiries", description: "Handbag", title: "Always ready, always pretty" },
    { imgSrc: "/images/works/img3.jpg", category: "work", description: "My job for your style", title: "Sewing thread" },
    { imgSrc: "/images/works/img10.jpg", category: "casual", description: "Casual but sparkling", title: "Sparkling velvet" },
    { imgSrc: "/images/works/img8.jpg", category: "fashion", description: "The show wait the lady is ready", title: "The actress watch the TV" },
    { imgSrc: "/images/works/img12.jpg", category: "fashion", description: "In the car", title: "where are you going darling ?" },
    { imgSrc: "/images/works/img9.jpg", category: "casual", description: "Arabian fashion week", title: "Arabian and fashion" },
    { imgSrc: "/images/works/img6.jpg", category: "fashion", description: "During the fashion week", title: "Fashion style" },
    { imgSrc: "/images/works/img13.jpg", category: "work", description: "Sewing machine", title: "Sew It!" },
    { imgSrc: "/images/works/img14.jpg", category: "fashion", description: "Hidden from the sun", title: "I'm too bright for the sun" },
    { imgSrc: "/images/works/img15.jpg", category: "fashion", description: "The expression of passion red", title: "Red girl power" },    
    { imgSrc: "/images/works/img16.jpg", category: "accessoiries", description: "Pink handbag", title: "Barbie handbag" },
    { imgSrc: "/images/works/img19.jpg", category: "accessoiries", description: "Sewing machine designed", title: "Sewing life" },
];

export default pictureData;


