import React from 'react';
import "./pageNotFound.css";

function PageNotFound() {
  return (
    <div className='page not_found_page'>

        <h1 className="not_found_page_title">La page n'existe pas</h1>

    </div>
  )
}

export default PageNotFound