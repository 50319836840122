import { motion } from 'framer-motion';


const features = [
    {
        title: 'Notre Vision',
        description: 'Chez Vayanasca, notre mission est de vous aider à définir et à exprimer votre style unique. Nous croyons que chaque femme mérite de se sentir spéciale et en confiance avec son image personnelle.',
        imgUrl: '../dressedwomand.png',
    },
    {
        title: 'Votre Parcours Personnalisé',
        description: 'Ensemble, nous explorerons des options de style qui correspondent à votre personnalité et à votre budget. Laissez-nous vous guider vers un look qui reflète votre beauté intérieure et extérieure.',
        imgUrl: '../designerwoman.png',
    },
    {
        title: 'Nos Engagements',
        description: "Identification de pièces qui mettent en valeur votre silhouette et reflètent votre personnalit. <br/> Conseils personnalisés basés sur votre morphologie, vos préférences et les tendances actuelles.  Recommandations et astuces pratiques pour gérer efficacement votre garde-robe. <br/> Suivi régulier pour s assurer que votre style évolue avec vous et votre rythme de vie.",
        imgUrl: '../vayaadvice.png',
    },
]




function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FeaturesBlocks = () => {

    const variantsFeatures = {
        hidden: { opacity: 0, y: 10 },
        visible: { opacity: 1, y: 0 }
    };



    return (
        <main className="py-10 lg:py-14">
            <div className="container px-5 mx-auto">
                {/* <div className="mb-8 space-y-5 lg:text-center lg:mb-16">
                    <h2 className="text-4xl font-bold sm:text-5xl">Powerful Aerial Performance</h2>
                    <p className="text-lg text-white sm:w-3/4 lg:w-2/4 xl:w-1/3 lg:mx-auto sm:text-xl">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis, aliquid!</p>
                </div> */}

                <div className="grid gap-y-10 lg:gap-y-0">
                    {features.map((feature, i) => (

                        <motion.div
                            key={i}
                            className="w-[97%] md:w-[100%] mx-auto col-span-12 sm:col-span-6 lg:col-span-4"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: i * 0.1 }}
                            variants={variantsFeatures}
                        >
                            <div key={i} className="grid items-center gap-5 lg:grid-cols-2 lg:gap-0">

                                <div className={classNames(i % 2 === 0 ? 'lg:pr-20' : 'lg:pl-20', 'space-y-4')}>
                                    <h3 className="introduction_title text-2xl font-semibold sm:text-4xl">{feature.title}</h3>
                                    <ul>
                                        {feature.description.split('<br/>').map((line, index) => (
                                            <li className="text-xl md:text-2xl color-wheat text-left leading-loose" key={index}>
                                                {index > 0 && <br />}
                                                {line}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={classNames(i % 2 === 0 ? 'lg:order-last' : '', 'bg-gray-100 p-[0.1rem] order-first')}>
                                    <img src={feature.imgUrl} alt={feature.title} />
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </main>
    );
}

export default FeaturesBlocks;
