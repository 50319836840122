import React from 'react';
import { motion } from 'framer-motion';
import "./videos.css";

function Videos() {

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3 // Adjust this value for stagger timing
      }
    }
  };

  const itemVariants = {
    hidden: { y: 8, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  const variantsFeatures = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
  };

  
  return (

    <motion.div
    className="aa"
    initial={{ width: 99}}
    animate={{ width: "100%"}}
    variants={variantsFeatures}
    viewport={{ once: true }}
    whileInView="visible"
    exit={{ x:window.innerWidth, transition: { duration: 0.05 }}}
    >

    <div className="video_page video_page_body">
      {/* Uncomment if you need the header */}
      {/* <h1 className="stylist_name">Vidéos</h1> */}

      <main className="video_body">
        <div className="video_container">

          <h2 className="video_container_title">Inspirations</h2>
          <motion.div 
            className="video_page"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.iframe 
              className="youtube_video"
              width="560" 
              height="315"
              src="https://www.youtube.com/embed/G1I3psDbD94" 
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen
              variants={itemVariants}
            ></motion.iframe>

            <motion.iframe 
              className="youtube_video"
              width="560" 
              height="315"
              src="https://www.youtube.com/embed/ELHEyFqCl7o" 
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen
              variants={itemVariants}
            ></motion.iframe>
          </motion.div>

          <h2 className="video_container_title">Entrevues</h2>
          <motion.div 
            className="video_page"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.iframe 
              className="youtube_video"
              width="560" 
              height="315"
              src="https://www.youtube.com/embed/k9Ll2lPJkJQ" 
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen
              variants={itemVariants}
            ></motion.iframe>

            <motion.iframe 
              className="youtube_video"
              width="560" 
              height="315"
              src="https://www.youtube.com/embed/hoKDrFyQDy0" 
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen
              variants={itemVariants}
            ></motion.iframe>
          </motion.div>

        </div>
      </main>
    </div>
    </motion.div>
  );
}

export default Videos;