import React from 'react'
const ContactItem = ({ iconPath, title, email, phone }) => (
    <div className="w-[90%] flex justify-start min-h-[8rem]  rounded-[3px] xl:min-h-[10rem] relative xl:left-2 border-gray-500 border-[0.010rem] 
    shrink-0 grow-0 basis-auto md:px-3 lg:w-[90%] lg:px-6 xl:w-[48%]">
        <div className="flex items-center justify-center">
            <div className="shrink-0">
                <div className="inline-block rounded-md bg-primary-100 py-4 px-4 md:px-0 text-primary">
                    <span class="text-[black] inline-block p-3 text-black rounded-full bg-[wheat] dark:bg-[wheat]">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d={iconPath} />
                        </svg>
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="h-6 w-6">
                    </svg>
                </div>
            </div>
            <div className="ml-6 grow leading-7">
                <p className="mb-2 tracking-wider pt-2 text-xl font-bold dark:text-white">{title}</p>
                <p className="text-white-500 tracking-wider text-md dark:text-white-200">{email}</p>
                <p className="text-white-500 tracking-wider dark:text-white-200">{phone}</p>
            </div>
        </div>
    </div>
);

export default ContactItem