import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Presentation from "./pages/Presentation/Presentation";
import Videos from './pages/Videos/Videos';
import Tarifs from './pages/Tarifs/Tarifs';
import Contact from './pages/Contact/Contact';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Photos from './pages/Photos/Photos';
import HomeTest from './components/HomeTest/HomeTest';
import Phototest from './pages/Photos/Photos';
import 'tailwindcss/tailwind.css'
import './styles/globals.css'
import Photoshuffle from './pages/Photos/Photoshuffle';
import Services from './pages/Photos/Services';
import { motion, AnimatePresence } from 'framer-motion';
import FAQ from './pages/FAQ/FAQ';
import { useState, useEffect } from 'react';

function App() {

  const location = useLocation();

  // if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  //   document.documentElement.classList.add('dark')
  // } else {
  //   document.documentElement.classList.remove('dark')
  // }

// const lightIcon = document.querySelector(".light-switch");
// const userTheme = localStorage.getItem("themeVana");
// const systemTheme = window.matchMedia("(prefers-color-scheme: dark").matches;



  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>

      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Presentation />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/tarifs" element={<Tarifs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/photos" element={<Photoshuffle />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/services" element={<Services />} />
        <Route path="/lookbook" element={<Photos />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
