import React, { useEffect, useRef } from 'react';
import Shuffle from 'shufflejs';
import PictureItem from '../../components/Picture/Picture';
import './photoshuffle.css';
import pictureData from '../../data/pictureItems';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { motion } from 'framer-motion';


function Photoshuffle() {
    
    const toutButtonRef = useRef(null);
    const gridRef = useRef(null);
    // const shuffleInstance = useRef(null);
    const [shuffleInstance, setShuffleInstance] = useState(null);
    const [loadedImages, setLoadedImages] = useState({});
    // const location = useLocation(); 


    const handleImageLoad = (imageId) => {
        setLoadedImages(prev => ({ ...prev, [imageId]: true }));
        if (shuffleInstance) {
            shuffleInstance.layout();
        }
    };
    
    useEffect(() => {
        const allLoaded = Object.keys(pictureData).length === Object.keys(loadedImages).length 
                          && Object.values(loadedImages).every(status => status);
        if (allLoaded && shuffleInstance) {
            // Update the layout after all images are loaded
            shuffleInstance.layout();
        }
    }, [loadedImages, pictureData, shuffleInstance]);
    


    function handleFilterClick(evt) {
        const btn = evt.currentTarget;
        const btnGroup = btn.getAttribute('data-group');
        const filterGroup = btnGroup === 'all' ? Shuffle.ALL_ITEMS : btnGroup;
        shuffleInstance.current.filter(filterGroup);

        document.querySelectorAll('.filter-options li').forEach((li) => {
            li.classList.remove('active');
        });
        btn.classList.add('active');
    }

    function handleFilterClick(evt) {
        const btn = evt.currentTarget;
        const btnGroup = btn.getAttribute('data-group');
        const filterGroup = btnGroup === 'all' ? Shuffle.ALL_ITEMS : btnGroup;

        if (shuffleInstance) {
            shuffleInstance.filter(filterGroup);
        }

        document.querySelectorAll('.filter-options li').forEach((li) => {
            li.classList.remove('active');
        });
        btn.classList.add('active');
    }

    // Initialize Shuffle instance
    useEffect(() => {
        if (gridRef.current) {
            const shuffle = new Shuffle(gridRef.current, {
                itemSelector: '.picture-item'
            });
            setShuffleInstance(shuffle);

            return () => {
                shuffle.destroy();
            };
        }
    }, []);


    const variantsFeatures = {
        hidden: { opacity: 0, y: 10 },
        visible: { opacity: 1, y: 0 }
    };


    
    const categoriesPicture = [
        { name: "all", translated: "Tout" },
        { name: "fashion", translated: "Fashion" },
        { name: "casual", translated: "Casual" },
        { name: "accessoiries", translated: "Accessoires" },
        { name: "work", translated: "Travail" },
    ];

    return (

        <motion.div
        className="aa"
        initial={{ width: 99}}
        animate={{ width: "100%"}}
        variants={variantsFeatures}
        viewport={{ once: true }}
        // initial="hidden"
        whileInView="visible"
        exit={{ x:window.innerWidth, transition: { duration: 0.05 }}}
        >
        <section className="section page">
            <div className="container mx-auto">
                <div className="flex justify-center">
                    <div className="w-full filters-group-wrap mb-3">
                        <div className="flex justify-center mt-10 mb-5">
                            <div className="filters-group-wrap">
                                <div className="filter-options">
                                    <ul className="category_list filter-options flex flex-wrap gap-4 justify-center">

                                        {categoriesPicture.map((item) => (
                                            <li
                                                key={item.name}
                                                ref={item.translated === "Tout" ? toutButtonRef : null}
                                                className={item.name === "all" ? "active text-2xl" : "text-2xl"}
                                                data-group={item.name}
                                                onClick={handleFilterClick}
                                            >
                                                <p className='cursor-pointer'>{item.translated}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={gridRef} className="min-h-[auto] h-auto md:flex justify-center">
                    {pictureData.map((item, index) => (
                        <PictureItem
                            key={index}
                            imgSrc={item.imgSrc}
                            onLoad={() => handleImageLoad(index)}
                            category={item.category}
                            description={item.description}
                            title={item.title}
                        />
                    ))}
                </div>
            </div>
        </section>
        </motion.div>
    );
}

export default Photoshuffle;
