import React from 'react';
import "./homeTest.css";

function PhotoCard(props) {

  return (
    <div className='card_portfolio_container'>
      <div className='card_portfolio'>
        <img className="card_portfolio_img" alt="image portfolio" src={props.imgSrc}></img>
      </div>
    </div>
  )
}

export default PhotoCard