import React from "react";
import "./tarifs.css";
import { BiCheck } from 'react-icons/bi';
// import { IconContext } from "react-icons";
import { motion } from "framer-motion";

const variantsFeatures = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 }
};


function Tarifs() {
  return (

    <motion.div
    className="aa"
    initial={{ width: 99}}
    animate={{ width: "100%"}}
    variants={variantsFeatures}
    viewport={{ once: true }}
    whileInView="visible"
    exit={{ x:window.innerWidth, transition: { duration: 0.05 }}}
    >
    <div className="page tarifs_page">
      {/* <h1 className="title_page">Tarifs</h1> */}

      <main>
        <section>
          <div className="prestation_container">
            <div className="prestation_container_card">

              <div className="tarif_card">
                <h2>Confiance en soi</h2>
             
                  <ul>
                    <li><BiCheck className="price_check"/><p>Consultation initiale par visio</p></li>
                    <li><BiCheck className="price_check"/><p>Définition d'Objectifs de Style</p></li>
                    <li><BiCheck className="price_check"/><p>Plan d'Action</p></li>
                  </ul>
                  <div className="tarif_page_prestation_container_card_price"><p>340€</p></div>
              </div>

              <div className="tarif_card">
                <h2>L’essentiel</h2>
             
                  <ul>
                    <li><BiCheck className="price_check"/><p>Analyse de la garde robe</p></li>
                    <li><BiCheck className="price_check"/><p>2 Heures de Shopping Accompagné</p></li>
                    <li><BiCheck className="price_check"/><p>Enseignement de ce qui peux être assorti</p></li>
                    <li><BiCheck className="price_check"/><p>Carnet d'addresse adapté partagé</p></li>
                    <li><BiCheck className="price_check"/><p>Support mensuel par mail pendant un an</p></li>

                  </ul>
                  <div className="tarif_page_prestation_container_card_price"><p>520€</p></div>
              </div>


              <div className="tarif_card">
                <h2>Relooking total</h2>
             
                  <ul>
                    <li><BiCheck className="price_check"/><p>Accompagnement sur deux journées</p></li>
                    <li><BiCheck className="price_check"/><p>Analyse morphologie</p></li>
                    <li><BiCheck className="price_check"/><p>Selection de différentes tenues sur le web</p></li>
                    <li><BiCheck className="price_check"/><p>Cours sur le choix technique des vétements</p></li>
                    <li><BiCheck className="price_check"/><p>Carnet d'addresse de professionnels adaptés</p></li>
                    <li><BiCheck className="price_check"/><p>Accompagnement sur deux journées</p></li>
                  </ul>
                  <div className="tarif_page_prestation_container_card_price"><p>1300€</p></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
    </motion.div>

  );
}

export default Tarifs;
