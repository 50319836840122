const PictureItem = ({ imgSrc, category, description, title }) => {
    return (
      <div className="md:w-1/3 w-1/3 p-3 picture-item h-auto" data-groups={`["${category}"]`}>
        <div className="relative overflow-hidden rounded h-auto group transition-all duration-500">
          <img src={imgSrc}
               className="rounded transition-all duration-500 h-auto group-hover:scale-105" 
               alt={description} />
          <div
             className="absolute inset-3 flex items-end cursor-pointer rounded bg-white p-3 opacity-0 transition-all duration-500 group-hover:opacity-20">
            <div>
              <p className="text-sm text-gray-400">{description}</p>
              <h6 className="text-base text-black font-medium">{title}</h6>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default PictureItem;