import React, { useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import "./navbar.css";



function Navbar() {

    const [status, setStatus] = useState('close');
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('dark-mode') === 'true');

    useEffect(() => {
        document.documentElement.classList.toggle('dark', isDarkMode);
        localStorage.setItem('dark-mode', isDarkMode);
    }, [isDarkMode]);

    const handleThemeChange = () => {
        setIsDarkMode(!isDarkMode);
    };

    const handleClick = () => {
        setStatus(!status);
    }
    return (

        <nav className="navbar">
            <div className="navbar_container">

                <div className={status === 'open' ? "navbar_menu active" : "navbar_menu"}>
                    <li className="stylist_name"><NavLink exact to="/" onClick={handleClick} >
                        Fashioniza
                    </NavLink></li>
                    <li><NavLink exact to="/" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        Presentation
                    </NavLink>
                    </li>
                    <li><NavLink exact to="/photos" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        Photos
                    </NavLink>
                    </li>
                    <li><NavLink to="/videos" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        Vidéos
                    </NavLink>
                    </li>

                    <li><NavLink to="/tarifs" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        Tarifs
                    </NavLink>
                    </li>


                    <li><NavLink to="/faq" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        FAQ
                    </NavLink>
                    </li>


                    <li><NavLink to="/contact" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        Contact
                    </NavLink>
                    </li>

                    {/* <li activeClassName="" onClick={handleThemeChange} className='text-white light:text-red-800 dark:text-red-800 relative top-16'
                    >
                        test
                    </li> */}

                    {/* <p className='relative top-16 left-4 text-white'>{isDarkMode ? 'Light' : 'Dark'} Mode</p> */}

                    <div className="flex flex-col justify-center relative right-4 items-center content-center top-8">
                        <input
                            type="checkbox"
                            name="light-switch"
                            className="light-switch sr-only"
                            checked={isDarkMode}
                            onChange={handleThemeChange}
                            id="light-switch"
                        />
                        <label class="relative cursor-pointer p-2" for="light-switch">

                            {isDarkMode ? 
                            (  <svg class="dark:hidden fill-white " width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                    <path class="fill-slate-300 fill-white" d="M7 0h2v2H7zM12.88 1.637l1.414 1.415-1.415 1.413-1.413-1.414zM14 7h2v2h-2zM12.95 14.433l-1.414-1.413 
                                    1.413-1.415 1.415 1.414zM7 14h2v2H7zM2.98 14.364l-1.413-1.415 1.414-1.414 1.414 1.415zM0 7h2v2H0zM3.05 1.706 
                                    4.463 3.12 3.05 4.535 1.636 3.12z" />
                                    <path class="fill-slate-400 fill-white" d="M8 4C5.8 4 4 5.8 4 8s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4Z" />
                                </svg>
                            ):
                            (
                            <svg class="light:hidden dark:block fill-white" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                <path class="fill-slate-400 fill-white" d="M6.2 1C3.2 1.8 1 4.6 1 7.9 1 11.8 4.2 15 8.1 15c3.3 0 6-2.2 
                                6.9-5.2C9.7 11.2 4.8 6.3 6.2 1Z" />
                                <path class="fill-slate-500 fill-white" d="M12.5 5a.625.625 0 0 1-.625-.625 1.252 1.252 0 0 0-1.25-1.25.625.625 0 1 1 0-1.25 
                                1.252 1.252 0 0 0 1.25-1.25.625.625 0 1 1 1.25 0c.001.69.56 1.249 1.25 1.25a.625.625 0 1 1 0 
                                1.25c-.69.001-1.249.56-1.25 1.25A.625.625 0 0 1 12.5 5Z" />
                            </svg>
                            )
                            }
                        </label>
                    </div>

                </div>

                <div
                    className="BurgerMenu__container"
                    role="button"
                    onClick={() => setStatus(status === 'open' ? 'close' : 'open')}
                >
                    <i className={status}></i>
                    <i className={status}></i>
                    <i className={status}></i>
                </div>
            </div>
        </nav>

    )

    // const [toggleMenu, setToggleMenu] = useState(false);
    // const [largeur, setLargeur] = useState(window.innerWidth);   

    // const toggleNavbar = () => {
    //   setToggleMenu(!toggleMenu);
    // }

    // useEffect (() => {

    //   const changeWidth = () => {
    //     setLargeur(window.innerWidth);
    //     if(window.innerWidth > 800) {
    //       setToggleMenu(false);
    //     }
    //   }

    //   window.addEventListener('resize', changeWidth);

    //   return () => {
    //     window.removeEventListener('resize', changeWidth);
    //   }
    //   }, [])

    //   return (

    //     <nav id="navigation">

    //     {(toggleMenu || largeur > 800) && (
    //       <div className="navbar">
    //         <div className="navlink_container">
    //           <NavLink exact to="/" 
    //           className="navLink" >Presentation
    //           </NavLink>

    //           <NavLink to="/videos" 
    //           className="navLink" >Vidéos
    //           </NavLink>

    //           <NavLink to="/contact" 
    //           className="navLink" >Contact
    //           </NavLink>
    //         </div>
    //       </div>
    //     )}
    //         <button onClick={toggleNavbar} className="burger_menu">Menu</button>

    //     </nav>
    //   )
}
export default Navbar